import './Intro.css'
import arrowUp from '../../images/arrowUp.svg'
import { useIntl } from 'react-intl'
import { useContext } from 'react';
import CurrentLanguageContext from '../../contexts/CurrentLanguageContext';

const Intro = ({onTeamClick}) => {
    const intl = useIntl();
    const isEng = useContext(CurrentLanguageContext);

    return(
    <section className={`intro ${!isEng && 'am'}`}>
        <h1 className='intro__title'>{intl.formatMessage({id: "Develop"})} <span className='intro__title text__gradient'>{intl.formatMessage({id: "solutions"})}<img className='intro__arrow' src={arrowUp} alt='arrowUp' /></span><br/> {intl.formatMessage({id: "Of tomorrow"})}</h1>
        <p className='intro__text'>{intl.formatMessage({id: "IntroText"})}</p>
        <div className='intro__actions'>
            <button type='click' className='btn border' onClick={onTeamClick}><span className='text__gradient'>{intl.formatMessage({id: "Join our team"})}</span></button>
            <a className='btn gradient link' href='#contacts'>{intl.formatMessage({id: "Contact us"})}</a>
        </div>
    </section>
    )
}

export default Intro;