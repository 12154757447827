import './App.css';
import { useState, useEffect } from 'react';
import About from './About/About';
import Contacts from './Contacts/Contacts';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import Intro from './Intro/Intro';
import Mission from './Mission/Mission';
import Product from './Product/Product';
import Team from './Team/Team';
import PopupWithForm from './PopupWithForm/PopupWithForm';
import PrivacyPopup from './PrivacyPopup/PrivacyPopup';
import LangPopup from './LangPopup/LangPopup';
import MenuPopup from './MenuPopup/MenuPopup';
import Ellipses from './Ellipses/Ellipses';
import Specifications from './Specifications/Specifications';
import { IntlProvider } from 'react-intl';
import { LOCALES } from '../i18n/locales'
import { messages } from '../i18n/messages'
import CurrentLanguageContext from '../contexts/CurrentLanguageContext';

const App = () => {

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupType, setPopupType] = useState('');
  const [isPrivacyPopupOpen, setIsPrivacyPopupOpen] = useState(false);
  const [isLangPopupOpen, setIsLangPopupOpen] = useState(false);
  const [isMenuPopupOpen, setIsMenuPopupOpen] = useState(false);
  const [isMob, setIsMob] = useState(false);
  const [currentLocale, setCurrentLocale] = useState(getInitialLocale())
  const [isEng, setIsEng] = useState(false)

  function getInitialLocale() {
    const savedLocale = localStorage.getItem('locale')
    return LOCALES[savedLocale] || LOCALES.ENGLISH
  }
 
  function handleTeamClick(){
    setPopupType('team');
    setIsPopupOpen(true)
  }

  function handePrivacyClick(){
    document.body.classList.add('noscroll');
    setIsPrivacyPopupOpen(true)
  }

  function handlePopupSubmit(){
    setIsPopupOpen(false);
  }

  function handleLangPopupClick(){
    setIsLangPopupOpen((prevState) => !prevState);
  }

  function handleLangChange(country){
    setCurrentLocale(LOCALES[country]);
    localStorage.setItem('locale', country);
    setIsLangPopupOpen(false);
  }

  function handleMenuPopupClick(){
    document.body.classList.add('noscroll');
    setIsMenuPopupOpen(true);
  }

  function closeAllPopups(){
    setIsPopupOpen(false);
    setIsPrivacyPopupOpen(false);
    setIsLangPopupOpen(false);
    setIsMenuPopupOpen(false);
    document.body.classList.remove('noscroll');
  }

  useEffect(() => {
    const closeByEscape = (e) => {
      if (e.key === 'Escape') {
        closeAllPopups();
      }
    };
    document.addEventListener('keydown', closeByEscape);
    return () => document.removeEventListener('keydown', closeByEscape)
  }, []);

  useEffect(() => {
    const closeOnClick = (e) => {
      if (e.target.classList.contains('popup')) {
        closeAllPopups();
      }
    };
    document.addEventListener('click', closeOnClick);
    return () => document.removeEventListener('click', closeOnClick)
  }, []);

  useEffect(() => {
    if (document.documentElement.scrollWidth < 1440 ) setIsMob(true)
    else setIsMob(false)
  }, [])

  useEffect(() => {
    currentLocale === 'en-US' ? setIsEng(true) : setIsEng(false)
  }, [currentLocale])

  return (
    <IntlProvider messages={messages[currentLocale]} locale={currentLocale} defaultLocale={LOCALES.ENGLISH}>
    <div className={`page ${!isEng && 'am'}`}>
        <CurrentLanguageContext.Provider value={isEng}>
          <div className='decor'>
            <Header onLangPopupClick={handleLangPopupClick} onMenuPopupClick={handleMenuPopupClick} />
            <section className='content'>
              <Intro  onTeamClick={handleTeamClick} />
              <Mission />
              <Product />
{/*               {!isMob && <Specifications />} */}
              <Ellipses />
            </section>
          </div>
{/*           {isMob && <Specifications />} */}
          <About />
          <Team onClick={handleTeamClick} />
          <Contacts />
          <Footer onClick={handePrivacyClick} />
          <PopupWithForm type={popupType} isOpen={isPopupOpen} onClose={closeAllPopups} onSubmit={handlePopupSubmit} />
          <PrivacyPopup isOpen={isPrivacyPopupOpen} onClose={closeAllPopups} />
          <LangPopup isOpen={isLangPopupOpen} onLangChange={handleLangChange} />
          <MenuPopup isOpen={isMenuPopupOpen} onLangPopupClick={handleLangPopupClick} onClose={closeAllPopups} />
        </CurrentLanguageContext.Provider>
      </div>
    </IntlProvider>
  );
}

export default App;
