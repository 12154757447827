import { useContext } from 'react';
import { useIntl } from 'react-intl';
import CurrentLanguageContext from '../../contexts/CurrentLanguageContext';
import './Footer.css'

const Footer = ({onClick}) => {
    const intl = useIntl();
    const isEng = useContext(CurrentLanguageContext);

    return(
        <footer className='footer'>
            <p className={`footer__rules ${!isEng && 'am'}`} onClick={onClick}>{intl.formatMessage({id: "Privacy Policy and Terms of Use"})}</p>
            <p className='footer__text'>{intl.formatMessage({id: "Copyright"})} &copy; 2022{intl.formatMessage({id: "FooterText"})}</p>
        </footer>
    )
}

export default Footer;