import './Team.css'
import team from '../../images/team.jpg'
import { useIntl } from 'react-intl'

const Team = ({onClick}) => {
    const intl = useIntl();
    
    return(
        <section className='team' id='team'>
            <img className='team__image' src={team} alt='team' />
            <div className='team__box'>
                <h2 className='product__title'>{intl.formatMessage({id: "Our"})} <span className='product__title text__gradient'>{intl.formatMessage({id: "team"})}</span></h2>
                <p className='team__text'>{intl.formatMessage({id: "TeamText"})}</p>
                <p className='team__text'>{intl.formatMessage({id: "TeamText2"})}</p>
                <button type='click' className='btn gradient team__btn' onClick={onClick}>{intl.formatMessage({id: "Join our team"})}</button>
            </div>
        </section>
    )
}

export default Team;