import { useContext } from 'react'
import profitLogo from '../../images/profit.svg'
import eng from '../../images/eng.png'
import arm from '../../images/arm.png'
import './Header.css'
import Navlinks from '../Navlinks/Navlinks'
import { useIntl } from 'react-intl'
import CurrentLanguageContext from '../../contexts/CurrentLanguageContext'

const Header = ({onLangPopupClick, onMenuPopupClick, onClose}) => {

    const intl = useIntl();
    const isEng = useContext(CurrentLanguageContext)
    const flag = isEng ? eng : arm

    return(
        <header className='header'>
        <a href='#' className='link'><img className='header__logo' alt='profit-logo' src={profitLogo} /></a>
        <Navlinks onClose={onClose} />
        <div className='header__menu'>
          <img className='header__icon' src={flag} alt='language icon'/>
          <p className='header__lang'>{intl.formatMessage({id: "Language"})}</p>
          <button type='click' className='header__lang-btn' onClick={onLangPopupClick} />
          <a href='#contacts' className='header__btn btn link'><span className='text__gradient'>{intl.formatMessage({id: "Contact us"})}</span></a>
        </div>
        <button type='click' className='header__menu-btn' onClick={onMenuPopupClick}></button>
      </header>
    )
}

export default Header;