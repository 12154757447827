import './Ellipses.css'

const Ellipses = () => {
    return(
        <>
          <div className='ellipse-1 ellipse1-4'></div>
          <div className='ellipse-2 ellipse2-3'></div>
          <div className='ellipse-3 ellipse2-3'></div>
          <div className='ellipse-4 ellipse1-4'></div>
        </>
    )
}

export default Ellipses;