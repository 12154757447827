import './ContactsEllipses.css'
const ContactEllipses = () => {
    return(
        <>
          <div className='ellipse-5 ellipse-contacts'></div>
          <div className='ellipse-6 ellipse-contacts'></div>
          <div className='ellipse-7 ellipse-contacts'></div>
        </>
    )
}

export default ContactEllipses;