import './LangPopup.css'
import ok from '../../images/Ok.svg'
import { useContext } from 'react';
import CurrentLanguageContext from '../../contexts/CurrentLanguageContext';

const LangPopup = ({isOpen, onLangChange}) => {

    const okImg = <img className='lang__choosen' alt='choosen' src={ok}></img>;
    const isEng = useContext(CurrentLanguageContext);

    
    function langChange(e){
        const lang = e.target.textContent.toUpperCase();
        onLangChange(lang)
    }

    return(
        <div className={`popup_type_lang ${isOpen && 'popup_type_lang_is-opened'}`}>
            <div className='lang__box'>
                <ul className='lang__list'>
                    <li className='lang__item'><button className='lang__btn' type='click' onClick={langChange}>English</button>{isEng && okImg }</li>
                    <li className='lang__item'><button className='lang__btn' type='click' onClick={langChange}>Armenian</button>{!isEng&& okImg}</li>
                </ul>
            </div>
        </div>
    )
}

export default LangPopup;