import { useContext } from 'react';
import CurrentLanguageContext from '../../contexts/CurrentLanguageContext';

const Navlink = ({text, link, isMob, onClose}) => {
    const isEng = useContext(CurrentLanguageContext);
    const navLinkClass = `nav__link link ${isMob && 'mobile'} ${!isEng && 'am'}`;

    return(
        <a className={navLinkClass} href={link} onClick={onClose}>{text}</a>
    )
}

export default Navlink;