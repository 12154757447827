import { useContext } from 'react';
import { useIntl } from 'react-intl';
import CurrentLanguageContext from '../../contexts/CurrentLanguageContext';
import AboutEllipses from '../Ellipses/AboutEllipses/AboutEllipses';
import './About.css';

const About = () => {
    const intl = useIntl();
    const isEng = useContext(CurrentLanguageContext);

    return(
        <section className={`about ${!isEng && 'am'}`}>
            <div className={`about__box ${!isEng && 'am'}`}>
                <h2 className={`about__title ${!isEng && 'am'}`}>{intl.formatMessage({id: "AboutTitle"})}</h2>
                <p className='about__text'>{intl.formatMessage({id: "AboutText"})}</p>
                <a className='btn about__btn link' href='#contacts'><span className='text__gradient'>{intl.formatMessage({id: "Contact us"})}</span></a>
            </div>
            <AboutEllipses />
        </section>
    )
}

export default About;