import Navlinks from "../Navlinks/Navlinks";
import eng from '../../images/eng.png';
import arm from '../../images/arm.png';
import './MenuPopup.css';
import ContactList from "../Contacts/ContactList";
import { useIntl } from "react-intl";
import { useContext } from "react";
import CurrentLanguageContext from "../../contexts/CurrentLanguageContext";

const MenuPopup = ({isOpen, onClose, onLangPopupClick}) => {
    const intl = useIntl();
    const isEng = useContext(CurrentLanguageContext);
    
    return (
        <div className={`popup popup_type_menu ${isOpen && 'popup_is-opened'}`}>
        <div className="menu__container">
            <button type="button" className="menu__close-btn" aria-label="Закрыть" onClick={onClose}></button>
            <div className='header__menu mobile'>
                <img className='header__icon mobile' src={isEng ? eng : arm} alt='language icon'/>
                <p className='header__lang mobile'>{intl.formatMessage({id: "Language"})}</p>
                <button type='click' className='header__lang-btn mobile' onClick={onLangPopupClick} />
            </div>
            <Navlinks type='mobile' onClose={onClose} />
            <ContactList type='mobile' />
        </div>
    </div>
    )
}

export default MenuPopup;