import pointer from '../../images/pointer.svg'
import phone from '../../images/phone.svg'
import mail from '../../images/mail.svg'
import pointer_blue from '../../images/pointer_blue.svg'
import phone_blue from '../../images/phone_blue.svg'
import mail_blue from '../../images/mail_blue.svg'
import { useIntl } from 'react-intl'
import { useContext } from 'react'
import CurrentLanguageContext from '../../contexts/CurrentLanguageContext'

const ContactList = ({type}) => {
    const intl = useIntl();
    const isMob = type === 'mobile';
    const isEng = useContext(CurrentLanguageContext);

    return(
        <ul className='contacts__list'>
            <li className={`contacts__item ${isMob && 'mobile'}`}><img className='contacts__image p-t' alt='map point' src={isMob ? pointer_blue : pointer} />{intl.formatMessage({id: "ContactAdress"})}</li>
            <li className={`contacts__item ${isMob && 'mobile'}`}><img className='contacts__image' alt='map point' src={isMob? phone_blue : phone} />+374 41 505236<span className={`contacts__tip ${!isEng && 'am'}`}>{intl.formatMessage({id: "For orders"})}</span></li>
            <li className={`contacts__item ${isMob && 'mobile'}`}><img className='contacts__image' alt='map point' src={isMob? phone_blue : phone} />+374 91 271337<span className={`contacts__tip ${!isEng && 'am'}`}>{intl.formatMessage({id: "For employment"})}</span></li>
            <li className={`contacts__item ${isMob && 'mobile'}`}><img className='contacts__image p-t' alt='map point' src={isMob? mail_blue : mail} />info@profit-soft.am</li>
        </ul>
    )
}

export default ContactList;