const MissionBox = ({title, text, image}) => {
    return(
        <div className='mission__box'>
            <div className='mission__header'>
            <img src={image} alt={title} className='mission__image' />
            <h3 className='mission__title'>{title}</h3>
            </div>
            <p className='mission__text'>{text}</p>
        </div>
    )
}

export default MissionBox;