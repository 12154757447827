import jira from '../../images/jira.png';
import figma from '../../images/figma.png'
import anaconda from '../../images/anaconda.png'
import middleimage from '../../images/middleimage.png'
import './Product.css'
import { useIntl } from 'react-intl';
import { useContext } from 'react';
import CurrentLanguageContext from '../../contexts/CurrentLanguageContext';

const Product = () => {
    const intl = useIntl();
    const isEng = useContext(CurrentLanguageContext);

    return (
        <section className='product' id='products'>
            <div className='product__box'>
            <div className={`product__info ${!isEng && 'am'}`}>
                <h2 className='product__title'>{intl.formatMessage({id: "Explore"})} <span className='product__title text__gradient'>{intl.formatMessage({id: "Profit"})}</span></h2>
                <p className='product__subtitle'>{intl.formatMessage({id: "ProductSubtitle"})}</p>
            </div>
            <p className='product__text'>{intl.formatMessage({id: "We provide access to:"})}</p> 
            <ul className='product__items'>
                <li className='product__item'><img src={figma} alt='figma' /></li>
                <li className='product__item'><img src={anaconda} alt='anaconda' /></li>
                <li className='product__item'><img src={jira} alt='jira' /></li>
            </ul>
            </div>
            <img className='product__image' alt='blue gradient arrows' src={middleimage}></img>
        </section>
    )
}

export default Product;