import { useIntl } from 'react-intl';
import Navlink from './Navlink';
import './Navlinks.css'

const Navlinks = ({type, onClose}) => {

    const isMob = type === 'mobile';
    const intl = useIntl();

    return(
        <nav className={`nav__links ${isMob && 'mobile'}`}>
            <Navlink text={intl.formatMessage({id: "Our products"})} link='#products' isMob={isMob} onClose={onClose} />
            <Navlink text={intl.formatMessage({id: "Our team"})} link='#team' isMob={isMob} onClose={onClose} />
            <Navlink text={intl.formatMessage({id: "Contacts"})} link='#contacts' isMob={isMob} onClose={onClose} />
        </nav>
    )
}

export default Navlinks;