import './Contacts.css'
import googlemap from '../../images/googlemap.png'
import ContactList from './ContactList'
import ContactsEllipses from '../Ellipses/ContactsEllipses/ContactsEllipses'
import { useIntl } from 'react-intl'


const Contacts = () => {
    const intl = useIntl();
    
    return(
        <section className='contacts' id='contacts'>
            <img src={googlemap} alt='location' className='contacts__map' />
            <div className='contacts__box'>
                <h2 className="contacts__title">{intl.formatMessage({id: "Contacts"})}</h2>
                <ContactList />
            </div>
            <ContactsEllipses />
        </section>
    )
}

export default Contacts;