import { useIntl } from 'react-intl';
import './PopupWithForm.css';

const PopupWithForm = ({ type, isOpen, onClose, onSubmit }) => {

    const popupTypeContact = type === 'contact';
    const title = popupTypeContact ? 'Contact us' : 'Join our team';
    const intl = useIntl();

    return(
        <div className={`popup popup_type_${type} ${isOpen && 'popup_is-opened'}`}>
            <div className="popup__container">
                <button type="button" className="popup__close-btn" aria-label="Закрыть" onClick={onClose}></button>
                <h2 className="popup__title">{intl.formatMessage({id: title})}</h2>
                    <div className='popup__section'>
                        <p className='intro__text'>{intl.formatMessage({id: "PopupText"})}</p>
                    </div>
                <button type="submit" className={`popup__save-btn btn contact gradient`} onClick={onClose}>{intl.formatMessage({id: "ok, thanks"})}</button>
            </div>
        </div>
    )
}

export default PopupWithForm;