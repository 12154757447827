import { useContext } from 'react';
import { useIntl } from 'react-intl';
import CurrentLanguageContext from '../../contexts/CurrentLanguageContext';
import {privacyrules} from '../../utils/privacyrules';
import './PrivacyPopup.css'

const PrivacyPopup = ({ isOpen, onClose }) => {
    const intl = useIntl();
    const isEng = useContext(CurrentLanguageContext);
    const rules = isEng ? privacyrules.english : privacyrules.armenian;

    return(
        <div className={`popup popup_type_privacy ${isOpen && 'popup_is-opened'}`}>
            <div className="popup__container privacy__container" >
                <div className='privacy__box'>
                    <button type="button" className="popup__close-btn" aria-label="Закрыть" onClick={onClose}></button>
                    <div className='privacy__textbox'>
                        <h2 className="popup__title privacy__title">{intl.formatMessage({id: "Privacy policy and terms of use"})}</h2>
                        <p className='privacy__text'>{rules}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPopup;