import './AboutEllipses.css'

const AboutEllipses = () => {
    return(
        <>
          <div className='ellipse-8 ellipse-about'></div>
          <div className='ellipse-9 ellipse-about'></div>
        </>
    )
}

export default AboutEllipses;