import mission from '../../images/mission.svg'
import vision from '../../images/vision.svg'
import goals from '../../images/goals.svg'
import MissionBox from './MissionBox'
import './Mission.css'
import { useIntl } from 'react-intl'

const Mission = () => {
    const intl = useIntl();

    return(
        <section className='mission'>
            <MissionBox 
            title={intl.formatMessage({id: "Our mission"})}
            text={intl.formatMessage({id: "MissionText"})}
            image={mission} />
            <MissionBox 
            title={intl.formatMessage({id: "Our vision"})}
            text={intl.formatMessage({id: "VisionText"})}
            image={vision} />
            <MissionBox 
            title={intl.formatMessage({id: "Our goals"})}
            text={intl.formatMessage({id: "GoalsText"})}
            image={goals} />
        </section>
    )
}

export default Mission;